<template>
  <div>
    <br><br><br>
    <Row type="flex" justify="center" :gutter="5">
      <Col :xs="20" :sm="8" :md="8" :lg="8">
        <Card>
          <p slot="title">登陆</p>
          <Form ref="formInline" :model="formInline" :rules="ruleInline">
            <FormItem prop="Username">
              <Input type="text" v-model="formInline.Username" placeholder="Username">
                <Icon type="ios-person-outline" slot="prepend"></Icon>
              </Input>
            </FormItem>
            <FormItem prop="Password">
              <Input type="password" v-model="formInline.Password" placeholder="Password">
                <Icon type="ios-lock-outline" slot="prepend"></Icon>
              </Input>
            </FormItem>
            <FormItem>
              <Button type="primary" @click="handleSubmit('formInline')">登陆</Button>
            </FormItem>
          </Form>
        </Card>

      </Col>
    </Row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formInline: {
        Username: '',
        Password: ''
      },
      ruleInline: {
        Username: [
          {required: true, message: '请输入用户名', trigger: 'blur'}
        ],
        Password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {type: 'string', min: 3, message: '密码长度不正确', trigger: 'blur'}
        ]
      }
    }
  },
  created() {
    document.title = "登录 - 消逝的红叶-我就是我，是不一样的烟火";
  },
  methods: {
    handleSubmit(name) {
      var that = this;
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$http.post('/login', this.formInline).then((response) => {
            console.log(response.data);
            if (200 === response.data.code) {
              that.$Message.success('登陆成功!');
              localStorage.setItem("token", response.data.data);
              that.$router.push('/life');
            } else {
              that.$Message.error(response.data.msg);
            }
          }, (response) => {
            that.$Message.error('http请求错误，登陆失败!', response);
          });
        } else {
          this.$Message.error('登陆失败!');
        }
      })
    }
  }
}
</script>
